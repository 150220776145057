<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Upload Upgrades Info File
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 650 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div class="row" v-if="loading == false">
            <div class="col-12 col-sm-12 col-xl-4">
              <form
                class="settings-form d-flex flex-column"
                action="#"
                @submit.prevent="uploadReports()"
              >
                <label for="" class="settings-label">Account</label>
                <div class="input-container">
                  <select class="select-input" name="carrier" id="carrier">
                    <option value="1">Telus (17512741)</option>
                    <option value="1">Bell (17512741)</option>
                    <option value="1">AT&T (17512741)</option>
                    <option value="1">Telus (54856984)</option>
                    <option value="1">Bell (35845985)</option>
                    <option value="1">AT&T (56941254)</option>
                  </select>
                </div>
                <label for="" class="settings-label">Select File</label>
                <div class="file-input mb-15px">
                  <input type="file" name="file1" id="file1" />
                  <button>Choose File</button>
                  <p>No file chosen</p>
                </div>
                <div class="d-flex align-items-center mt-4">
                  <button type="submit" class="button-expertel mt-0">
                    Upload File
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      loading: true,
      notification_settings: []
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        // this.calculateHeight();
        this.loading = false;
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    uploadReports() {
      this.makeToast("Success", "Form submited", "success");
    }
  }
};
</script>
